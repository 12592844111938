import type { StyleRule } from '@archipro-design/aria';
import { virtualListItemClassName } from '@archipro-design/aria';
import {
    virtualListClassName,
    virtualListSlotClassNames,
} from '@archipro-design/aria';
import { getScrollbarStyle } from '@archipro-design/aria';
import {
    captionClassName,
    pxArrayToRem,
    pxToRem,
    switchClassName,
    flexClassName,
} from '@archipro-design/aria';
import {
    buttonClassName,
    inputClassName,
    inputSlotClassNames,
    labelClassName,
    svgIconClassName,
} from '@fluentui/react-northstar';
import { PRIVATE_TEXT_TOGGLE_FONT_SIZE } from '../../config/right-side-design-board-drawer';

export const RootContainer: StyleRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    width: pxToRem(498),
    padding: pxArrayToRem([0, 0, 32, 0]),

    [`& .closeButton`]: {
        position: 'absolute',
        top: pxToRem(28),
        right: pxToRem(34),
    },
});

export const DrawerContent: StyleRule = () => ({
    width: pxToRem(352),
    margin: pxArrayToRem([34, 28.5, 34, 33.5]),
});

export const CloseButton: StyleRule = () => ({
    position: 'absolute',
    top: pxToRem(27),
    right: pxToRem(31),
    [`& .${buttonClassName}`]: {
        ':hover': {
            backgroundColor: 'transparent',
        },
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
});

export const SaveButton: StyleRule = () => ({
    position: 'absolute',
    bottom: 0,
    width: pxToRem(346),
    marginBottom: pxToRem(7),
    [`& .${buttonClassName}`]: {
        backgroundColor: 'transparent',
    },
});

const InputClass: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([0, 0, 33]),
    [`& .${captionClassName}`]: {
        ...theme.siteVariables.textStyles.Text.Medium65,
        marginBottom: pxToRem(18),
        fontSize: pxToRem(14),
    },
    [theme.screen.laptop]: {
        marginBottom: pxToRem(24),
    },
});

const InputStyle: StyleRule = ({ theme }) => ({
    backgroundColor: 'rgba(243, 243, 243, 1)',
    color: 'rgba(158, 158, 158, 1)',
    width: '-webkit-fill-available',
    border: 0,
    lineHeight: '130%',
    fontSize: `max(16px, ${pxToRem(16)})`, // Prevent iOS from zooming in on input focus
    ...theme.siteVariables.textStyles.Text.Roman55,
    padding: pxArrayToRem([23, 13, 22]),

    [theme.screen.laptop]: {
        fontSize: pxToRem(14),
        padding: pxArrayToRem([12, 14]),
    },
});

export const SearchTitleInputStyle: StyleRule = ({ theme }) => ({
    ...InputClass({ theme }),
    marginBottom: pxToRem(27),
    width: 'inherit',
    [`& .${inputSlotClassNames.input}`]: {
        ...InputStyle({ theme }),
        color: theme.siteVariables.colors.charcoal['000'],
        [`&::placeholder`]: {
            color: '#9e9e9e',
        },
    },
});

export const NewTitleInputStyle: StyleRule<{ titleError: boolean }> = ({
    theme,
    titleError,
}) => ({
    ...InputClass({ theme }),
    margin: pxArrayToRem([37, 0, 0]),

    position: 'relative',
    width: 'inherit',
    [`& .${inputSlotClassNames.input}`]: {
        ...InputStyle({ theme }),
        color: theme.siteVariables.colors.charcoal['000'],
        [`&::placeholder`]: {
            color: '#9e9e9e',
        },
    },
    marginBottom: titleError ? pxToRem(22) : pxToRem(0),

    [theme.screen.laptop]: {
        marginBottom: pxToRem(1),
        marginTop: pxToRem(52),
        [`& .${inputSlotClassNames.input}`]: {
            marginBottom: pxToRem(18),
        },
    },
});

export const DescriptionInputStyle: StyleRule = ({ theme }) => ({
    ...InputClass({ theme }),
    [`& > textarea`]: {
        ...InputStyle({ theme }),
        outline: 'none',
        resize: 'none',
        height: pxToRem(174),
        lineHeight: 'inherit',
        color: theme.siteVariables.colors.charcoal['000'],
        width: '100%',
    },

    [theme.screen.laptop]: {
        [`& > textarea`]: {
            height: pxToRem(98),
        },
    },
});

export const testDiv: StyleRule = () => ({
    position: '',
    bottom: 0,
});

export const FormContentsStyle: StyleRule<{ titleError?: boolean }> = ({
    theme,
    titleError,
}) => ({
    marginBottom: pxToRem(22),
    ...getScrollbarStyle(theme, pxArrayToRem([0, 0, 1, 0])),
    height: '100%',
    overflowX: 'hidden',
    [theme.screen.laptop]: {
        overflow: 'unset',
        [`& .${captionClassName}`]: {
            marginTop: pxToRem(8),
            fontSize: pxToRem(14),
            marginBottom: pxToRem(18),
            ...theme.siteVariables.textStyles.Text.Medium65,
        },
        [`& .${inputClassName}`]: {
            marginBottom: pxToRem(18),
        },
    },
    [`& .${inputClassName}`]: {
        marginBottom: pxToRem(titleError ? 28 : 10),
    },
});

export const FormContentListStyle: StyleRule = ({ theme }) => ({
    borderTop: `1px solid ${theme.siteVariables.colors.grey[150]}`,
    width: `calc(100% + ${pxToRem(36)})`,
    height: '100%',

    paddingLeft: pxToRem(18),
    left: pxToRem(-18),
    position: 'relative',
    overflow: 'scrolll',

    [theme.screen.laptop]: {
        width: '100%',
        borderTop: `none`,
        paddingRight: 'unset',
        paddingLeft: 'unset',
        left: 'unset',
        position: 'relative',
        overflow: 'unset',
    },
});

export const BoardVisibleStatusStyle: StyleRule = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToRem(PRIVATE_TEXT_TOGGLE_FONT_SIZE),
    marginTop: pxToRem(44),
    paddingLeft: pxToRem(1),
    [theme.screen.laptop]: {
        paddingLeft: 0,
    },
    [`& .${svgIconClassName}`]: {
        width: pxToRem(PRIVATE_TEXT_TOGGLE_FONT_SIZE),
        display: 'inline-block',
    },
    [`& .${switchClassName}`]: {
        fontSize: pxToRem(PRIVATE_TEXT_TOGGLE_FONT_SIZE),
        [`& .${labelClassName}`]: {
            lineHeight: pxToRem(20),
        },
    },
    [`& .${flexClassName}`]: {
        flexGrow: 1,
    },
    [`& .${labelClassName}`]: {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(21),
        marginTop: pxToRem(1),
        marginRight: pxToRem(2),
    },
    [`& span`]: {
        marginTop: pxToRem(1),
        alignItems: 'flex-start',
    },
    '& img': {
        marginLeft: pxToRem(2),
        height: pxToRem(20),
    },
});

export const ErrorStyle: StyleRule = ({ theme }) => ({
    position: 'absolute',
    bottom: pxToRem(24),
    '&': {
        margin: 0,
        height: 0,
        fontSize: pxToRem(12),
        marginBottom: 0,
        color: theme.siteVariables.colors.danger['100'],
    },
    [theme.screen.laptop]: {
        bottom: pxToRem(18),
    },
});

export const PrivacyContainer: StyleRule = ({ theme }) => ({
    paddingRight: pxToRem(10),
    [theme.screen.laptop]: {
        paddingRight: 0,
    },
});

export const Header: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    fontSize: pxToRem(36),
});

export const CenterText: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21),
    paddingRight: pxToRem(10),
    [theme.screen.laptop]: {
        paddingRight: 0,
    },
});

export const BoardVisibleStatusText: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    position: 'relative',
    marginLeft: pxToRem(8),
});

export const ToastMessageStyle: StyleRule = () => ({
    position: 'absolute',
    left: '50%',
});

export const PinSelectBox: StyleRule = ({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    [theme.screen.laptop]: {
        overflow: 'unset',
        height: '100%',
    },
    [`& .${virtualListClassName}`]: {
        [`& .${virtualListSlotClassNames.listContainer}`]: {
            overflow: 'hidden !important',
            [theme.screen.laptop]: {
                overflow: 'auto !important',
            },
        },
        [theme.screen.laptop]: {
            height: 'inherit',
        },
    },
    [`& .${virtualListItemClassName}`]: {
        paddingRight: pxToRem(13),
        [theme.screen.laptop]: {
            paddingRight: 0,
        },
    },
});

export const SearchBoxWrapper: StyleRule = () => ({
    height: pxToRem(60),
    [`& .${captionClassName}`]: {
        display: 'block',
        padding: pxArrayToRem([20, 20, 20, 0]),
        fontWeight: 500,
    },
});
