import { Input, Switch, useAppDisplayModeContext } from '@archipro-design/aria';
import { Flex, Caption, useStyles, pxToRem } from '@archipro-design/aria';

import * as S from './NewDesignBoardBody.style';
import LockIcon from './LockIcon.svg?url';
import {
    isPrivateExplanation,
    isPublicExplanation,
} from '../../config/right-side-design-board-drawer';
import type { Control, FieldError } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { KeyboardEvent } from 'react';
import type { NewBoardFormShape } from '@modules/design-board/validation/DesignBoardDetailPageSchemas';

interface FilterDrawerProps {
    control: Control<NewBoardFormShape>;
    errors: { title?: FieldError };
}

const NewDesignBoardBody = ({ control, errors }: FilterDrawerProps) => {
    const { css } = useStyles({ titleError: errors.title ? true : false });
    const { mobile } = useAppDisplayModeContext();

    return (
        <Flex column className={css(S.FormContentsStyle)}>
            <Controller
                control={control}
                name={'title'}
                render={({ field, fieldState: { error } }) => (
                    <div className={css(S.NewTitleInputStyle)}>
                        {!mobile && (
                            <Caption variant="03">Design board title*</Caption>
                        )}
                        <Input
                            {...field}
                            fluid
                            placeholder={`Enter board title${
                                mobile ? '*' : ''
                            }`}
                            autoComplete={'on'}
                            autoFocus
                            showSuccessIndicator={false}
                            onKeyDown={(e: KeyboardEvent) => {
                                const key = e.code;
                                if (key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                        {error && (
                            <div className={css(S.ErrorStyle)}>
                                {error.message}
                            </div>
                        )}
                    </div>
                )}
            />

            <div className={css(S.DescriptionInputStyle)}>
                {!mobile && <Caption variant="03">Description</Caption>}
                <Controller
                    control={control}
                    name={'description'}
                    render={({ field }) => {
                        return (
                            <textarea
                                placeholder={'Enter description'}
                                {...field}
                            />
                        );
                    }}
                />
            </div>

            <Controller
                control={control}
                defaultValue={true}
                name={'isPrivate'}
                render={({ field }) => (
                    <div className={css(S.PrivacyContainer)}>
                        <div className={css(S.CenterText)}>
                            {field.value
                                ? isPrivateExplanation
                                : isPublicExplanation}
                        </div>

                        <div className={css(S.BoardVisibleStatusStyle)}>
                            <Flex>
                                {field.value && (
                                    <img
                                        src={LockIcon}
                                        alt="Board is private"
                                    />
                                )}
                                <div className={css(S.BoardVisibleStatusText)}>
                                    This board is{' '}
                                    {field.value ? 'private' : 'public'}
                                </div>
                            </Flex>
                            <Switch
                                variables={{
                                    switchWidth: pxToRem(36),
                                    switchHeight: pxToRem(18),
                                    thumbHeight: pxToRem(16),
                                    thumbWidth: pxToRem(16),
                                }}
                                checked={field.value}
                                label={field.value ? `Private` : `Public`}
                                {...field}
                                onChange={() => field.onChange(!field.value)}
                                defaultChecked={true}
                            />
                        </div>
                    </div>
                )}
            />
        </Flex>
    );
};

export default NewDesignBoardBody;
