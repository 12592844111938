import type { StyleRule } from '@archipro-design/aria';
import {
    captionClassName,
    pxArrayToRem,
    pxToRem,
    switchClassName,
} from '@archipro-design/aria';
import {
    buttonClassName,
    dialogClassName,
    dialogSlotClassNames,
    inputSlotClassNames,
    labelClassName,
    svgIconClassName,
} from '@fluentui/react-northstar';
import { PRIVATE_TEXT_TOGGLE_FONT_SIZE } from '../../config/right-side-design-board-drawer';
import type { ButtonSecondaryProps } from './DesignBoardRightSideDrawerFooter';
import { getDvhStyles } from '~/modules/design-board/util';

export const RootContainer: StyleRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    width: pxToRem(498),
    padding: pxArrayToRem([0, 0, 32, 0]),

    [`& .closeButton`]: {
        position: 'absolute',
        top: pxToRem(28),
        right: pxToRem(34),
    },
});

export const DrawerContent: StyleRule = () => ({
    width: pxToRem(352),
    margin: pxArrayToRem([34, 28.5, 34, 33.5]),
});

export const CloseButton: StyleRule = ({ theme }) => ({
    position: 'absolute',
    top: pxToRem(28),
    right: pxToRem(24),
    [`& .${buttonClassName}`]: {
        ':hover': {
            backgroundColor: 'transparent',
        },
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    [`& .${svgIconClassName}`]: {
        width: pxToRem(24),
    },
    [theme.screen.laptop]: {
        [`& .${svgIconClassName}`]: {
            width: 'unset',
        },
        right: pxToRem(31),
    },
});

export const SaveButton: StyleRule<{
    buttonSecondary?: ButtonSecondaryProps;
}> = ({ theme }) => ({
    width: '100%',
    marginBottom: pxToRem(2),
    [`& .${buttonClassName}`]: {
        letterSpacing: '0.05em',
        backgroundColor: 'transparent',
        fontSize: pxToRem(12),
        lineHeight: pxToRem(12),
        marginTop: pxToRem(2),
    },

    [theme.screen.laptop]: {
        marginBottom: pxToRem(0),
        width: pxToRem(346),
        height: pxToRem(53),
        [`& .${buttonClassName}`]: {
            fontSize: pxToRem(14),
            lineHeight: pxToRem(14),
            marginTop: 0,
        },
    },
});

export const SecondaryButton: StyleRule<{
    buttonSecondary?: ButtonSecondaryProps;
}> = ({ theme, buttonSecondary }) => ({
    marginTop: pxToRem(buttonSecondary ? 8 : 8),
    borderColor: theme.siteVariables.colors.charcoal['000'],
    marginBottom: pxToRem(2),

    [theme.screen.laptop]: {
        marginTop: pxToRem(8),
        marginBottom: 0,
        width: pxToRem(346),
        height: pxToRem(53),
    },
});

export const BottomButtons: StyleRule = ({ theme }) => ({
    height: '100%',
    flexFlow: 'column-reverse',
    minHeight: pxToRem(72),
    display: 'flex',
    flexShrink: 5,
    width: '100%',
    [`& .${labelClassName}`]: {
        letterSpacing: pxToRem(0.9),
    },

    [theme.screen.laptop]: {
        display: 'flex',
        minHeight: 'unset',
        [`& .${labelClassName}`]: {
            letterSpacing: 0,
            [`& .${labelClassName}`]: {
                letterSpacing: pxToRem(0.9),
            },
        },
    },
});

const InputClass: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([0, 0, 24]),
    [`& .${captionClassName}`]: {
        ...theme.siteVariables.textStyles.Text.Medium65,
        marginBottom: pxToRem(18),
        fontSize: pxToRem(14),
    },
});

const InputStyle: StyleRule = () => ({
    backgroundColor: 'rgba(243, 243, 243, 1)',
    color: 'rgba(158, 158, 158, 1)',
    width: '-webkit-fill-available',
    border: 0,
    fontSize: pxToRem(14),
    padding: pxArrayToRem([12, 14]),
});

export const TitleInputStyle: StyleRule = ({ theme }) => ({
    ...InputClass({ theme }),
    marginBottom: pxToRem(27),
    [`& .${inputSlotClassNames.input}`]: {
        ...InputStyle({ theme }),
        color: theme.siteVariables.colors.charcoal['000'],
    },
});

export const DescriptionInputStyle: StyleRule = ({ theme }) => ({
    ...InputClass({ theme }),
    [`& > textarea`]: {
        ...InputStyle({ theme }),
        resize: 'none',
        height: pxToRem(98),
        lineHeight: 'inherit',
    },
});

export const testDiv: StyleRule = () => ({
    position: '',
    bottom: 0,
});

export const FormContentsStyle: StyleRule = () => ({
    marginTop: pxToRem(60),
});

export const BoardVisibleStatusStyle: StyleRule = () => ({
    display: 'inline-block',
    fontSize: pxToRem(PRIVATE_TEXT_TOGGLE_FONT_SIZE),
    marginTop: pxToRem(44),
    [`& .${svgIconClassName}`]: {
        width: pxToRem(PRIVATE_TEXT_TOGGLE_FONT_SIZE),
        display: 'inline-block',
    },
    [`& .${switchClassName}`]: {
        display: 'inline-flex',
        right: pxToRem(33.5),
        position: 'absolute',
        fontSize: pxToRem(PRIVATE_TEXT_TOGGLE_FONT_SIZE),
    },
    [`& .${labelClassName}`]: {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(21),
        marginTop: pxToRem(1),
        marginRight: pxToRem(2),
    },
    [`& span`]: {
        marginTop: pxToRem(1),
        alignItems: 'flex-start',
    },
    '& img': {
        marginLeft: pxToRem(2),
    },
});

export const ErrorStyle: StyleRule = ({ theme }) => ({
    '&': {
        margin: 0,
        height: 0,
        fontSize: pxToRem(12),
        marginBottom: 0,
        color: theme.siteVariables.colors.danger['100'],
    },
});

export const Header: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    fontSize: pxToRem(20),
    letterSpacing: '0.015em',
    wordSpacing: '-0.05em',

    [theme.screen.laptop]: {
        fontSize: pxToRem(36),
        wordSpacing: 0,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
    },
});

export const CenterText: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21),
});

export const BoardVisibleStatusText: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    display: 'inline',
    top: pxToRem(-4),
    position: 'relative',
    marginLeft: pxToRem(8),
});

export const ToastMessageStyle: StyleRule = () => ({
    position: 'absolute',
    left: '50%',
});

export const FormClass: StyleRule = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.siteVariables.colors.white['FFF'],
    padding: pxArrayToRem([31, 18, 18, 18]),
    width: '100vw',
    ...getDvhStyles(),

    [theme.screen.laptop]: {
        padding: pxArrayToRem([34]),
        width: pxToRem(414),
    },

    [`& .closeButton`]: {
        position: 'absolute',
        right: pxToRem(32),
        width: pxToRem(346),
    },
});

export const DeleteDialog: StyleRule = () => ({
    minHeight: pxToRem(250),
    [`&.${dialogClassName}`]: {
        borderRadius: 0,
        width: pxToRem(450),
        maxWidth: '100vw',
        padding: 0,
    },
    [`& .${dialogSlotClassNames.content}`]: {
        padding: 0,
    },
});
