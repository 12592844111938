import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { buttonClassName } from '@fluentui/react-northstar';
import type { LoadingStatus } from './DesignBoardDrawerSelectPinsListItem';

export const BoardListItem: StyleRule<{
    loadingStatus: LoadingStatus;
    isFavourited: boolean;
}> = ({ theme, loadingStatus, isFavourited = false }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.siteVariables.colors.grey[125]}`,
    whiteSpace: 'nowrap',
    position: 'relative',
    alignItems: 'center',

    [`& label`]: {
        lineClamp: '1',
        WebkitLineClamp: '1',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        wordBreak: 'break-word',
        whiteSpace: 'nowrap',
        display: 'block',
        fontSize: pxToRem(16),
        cursor: loadingStatus === 'NoLoad' ? 'pointer' : 'not-allowed',
    },

    paddingTop: pxToRem(21),
    paddingBottom: pxToRem(19),
    height: 'auto',

    [`& .${buttonClassName}`]: {
        marginLeft: pxToRem(49),
        borderRadius: pxToRem(2),
    },

    [theme.screen.laptop]: {
        height: pxToRem(34),
        padding: 0,
        [`& label`]: {
            fontSize: 'intial',
        },
        [`& .${buttonClassName}`]: {
            borderRadius: 0,
            marginLeft: pxToRem(10),
            height: '100%',
            display:
                loadingStatus !== 'SelectedId' && !isFavourited
                    ? 'none'
                    : 'inline-flex',
            background: isFavourited
                ? theme.siteVariables.colors.white[300]
                : theme.siteVariables.colors.charcoal[250],
        },

        ':hover': {
            background: theme.siteVariables.colors.white[250],
            [`& .${buttonClassName}`]: {
                display:
                    loadingStatus !== 'NotSelectedId' ? 'inline-flex' : 'none',
                background: isFavourited
                    ? theme.siteVariables.colors.white[300]
                    : theme.siteVariables.colors.charcoal[250],
            },
        },
    },
    cursor: loadingStatus === 'NoLoad' ? 'pointer' : 'not-allowed',
});
