import { Caption, Input, VirtualList, useStyles } from '@archipro-design/aria';
import { useFetcher } from '@remix-run/react';
import type { MemberDesignBoardTextOnlyListQuery } from 'generated/graphql';
import { useCallback, useEffect, useState } from 'react';
import { GET_DESIGN_BOARD_TEXT_ONLY_LIST_API } from '../../config/design-board-config';
import * as S from './NewDesignBoardBody.style';
import type { BoardListItem } from '~/modules/design-board/type';
import { useSaveToDesignBoardContext } from '~/modules/design-board/component/context';
import { fetcherIsDone } from '~/utils/fetcherHelper';
interface SearchDesignBoardListProps {
    headerLabel: string;
    virtualListItemFn: (data: BoardListItem) => JSX.Element;
}

const DISABLE_SEARCH = true;

const SearchDesignBoardList = ({
    headerLabel,
    virtualListItemFn,
}: SearchDesignBoardListProps) => {
    const { css } = useStyles();
    const [totalBoards, setTotalBoards] = useState<number | null>(null);

    const [filteredBoards, setFilteredBoards] = useState<BoardListItem[]>([]);

    const [initialBoardListLength, setInitialBoardListLength] = useState<
        number | null
    >(null);

    const [isLoading, setIsLoading] = useState(false);
    const { dispatch, state } = useSaveToDesignBoardContext();

    const queryFetcher = useFetcher<MemberDesignBoardTextOnlyListQuery>();

    const fetchUserDesignBoardsUpdate = useCallback(
        async (index: number, forceRefetch = false) => {
            if (!isLoading || forceRefetch) {
                setIsLoading(true);
                const topLimit =
                    totalBoards !== null ? totalBoards - index : 10;

                const limit = Math.min(topLimit, 10).toString();
                queryFetcher.submit(
                    {
                        offset: `${index}`,
                        limit,
                        //searchKeyword: {title} //this is where we will want to implement the search
                    },
                    {
                        method: 'get',
                        action: GET_DESIGN_BOARD_TEXT_ONLY_LIST_API,
                    }
                );
            }
        },
        [isLoading, queryFetcher, totalBoards]
    );

    const virtualListItems =
        filteredBoards.length > 0 ? filteredBoards : state.myBoardList;

    useEffect(() => {
        const originData = queryFetcher.data?.getMember?.DesignBoards?.edges;
        if (
            originData &&
            originData.length &&
            isLoading &&
            fetcherIsDone(queryFetcher)
        ) {
            const totalCountData =
                queryFetcher.data?.getMember.DesignBoards?.pageInfo.totalCount;
            if (totalCountData) setTotalBoards(totalCountData);

            setFilteredBoards([
                ...virtualListItems,
                ...originData.reduce((arr: BoardListItem[], edge) => {
                    const board = edge?.node;
                    board &&
                        arr.push({
                            id: board.ID,
                            boardName: board.Title,
                            urlSegment: board.URLSegment,
                        });
                    return arr;
                }, []),
            ]);
            if (initialBoardListLength === null) {
                setInitialBoardListLength(originData.length);
            }
            setIsLoading(false);
        }
    }, [virtualListItems, initialBoardListLength, isLoading, queryFetcher]);

    useEffect(() => {
        if (filteredBoards.length > 0) {
            dispatch({
                type: 'SetBoardPinData',
                payload: { myBoardList: filteredBoards },
            });
        }
    }, [filteredBoards, dispatch]);

    return (
        <>
            <div className={css(S.SearchBoxWrapper)}>
                {!DISABLE_SEARCH && (
                    <Caption variant="03">{headerLabel}</Caption>
                )}
                {!DISABLE_SEARCH &&
                    initialBoardListLength !== null &&
                    initialBoardListLength > 10 && (
                        <Input
                            placeholder={'Search'}
                            className={css(S.SearchTitleInputStyle)}
                        />
                    )}
            </div>

            <div className={css(S.FormContentsStyle, S.FormContentListStyle)}>
                <div className={css(S.PinSelectBox)}>
                    <VirtualList<BoardListItem>
                        items={virtualListItems}
                        hasMoreItems={
                            (totalBoards === null ||
                                filteredBoards.length < totalBoards - 1) &&
                            isLoading === false
                        }
                        loadMoreItems={async (e) => {
                            fetchUserDesignBoardsUpdate(e);
                        }}
                    >
                        {({ data }) => {
                            return data ? virtualListItemFn(data) : null;
                        }}
                    </VirtualList>
                </div>
            </div>
        </>
    );
};

export default SearchDesignBoardList;
