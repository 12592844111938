import { Drawer } from '@archipro-design/aria';
import type { ReactNode, Reducer } from 'react';
import { useMemo } from 'react';
import { createContext, useContext, useReducer } from 'react';
import type { BoardListItem } from '~/modules/design-board/type';

export interface SaveToDesignBoardState {
    isDrawerOpen: boolean;
    isDrawerSwipeable: boolean;
    drawerAnchor: 'left' | 'right' | 'top' | 'bottom';
    drawerChild?: ReactNode;
    drawerClassName?: string;
    drawerContentClassName?: string;
    savedPinIds: number[];
    myBoardList: BoardListItem[];
}

export interface SaveToDesignBoardAction {
    type: 'SetBoardPinData' | 'OpenDrawer' | 'CloseDrawer';
    payload?: Partial<SaveToDesignBoardState>;
}

export type SaveToDesignBoardDispatch = (
    action: SaveToDesignBoardAction
) => void;

const swipeToCloseMapping = {
    left: 'left',
    right: 'right',
    top: 'up',
    bottom: 'down',
};

export const SaveToDesignBoardContext = createContext<
    | {
          state: SaveToDesignBoardState;
          dispatch: SaveToDesignBoardDispatch;
      }
    | undefined
>(undefined);

const SaveToDesignBoardReducer: Reducer<
    SaveToDesignBoardState,
    SaveToDesignBoardAction
> = (state, action) => {
    switch (action.type) {
        case 'OpenDrawer':
            return {
                ...state,
                ...action.payload,
                isDrawerOpen: true,
            };
        case 'CloseDrawer':
            return {
                ...state,
                ...action.payload,
                isDrawerOpen: false,
            };
        case 'SetBoardPinData':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const SaveToDesignBoardProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(SaveToDesignBoardReducer, {
        isDrawerOpen: false,
        drawerAnchor: 'right',
        savedPinIds: [],
        myBoardList: [],
        isDrawerSwipeable: false,
    });

    const value = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state]);

    return (
        <SaveToDesignBoardContext.Provider value={value}>
            {children}

            {state.drawerChild && (
                <Drawer
                    onOpenChange={() => dispatch({ type: 'CloseDrawer' })}
                    open={state.isDrawerOpen}
                    fluid={true}
                    anchor={state.drawerAnchor}
                    content={{
                        content: state.drawerChild,
                        className: state.drawerContentClassName,
                    }}
                    swipeableEdge={
                        state.isDrawerSwipeable
                            ? {
                                  onEdgeSwipe: (e, dir) => {
                                      dir ===
                                          swipeToCloseMapping[
                                              state.drawerAnchor
                                          ] &&
                                          dispatch({ type: 'CloseDrawer' });
                                  },
                              }
                            : undefined
                    }
                    className={state.drawerClassName}
                />
            )}
        </SaveToDesignBoardContext.Provider>
    );
};

export const useSaveToDesignBoardContext = () => {
    const context = useContext(SaveToDesignBoardContext);

    if (!context) {
        throw new Error(
            'Cannot get SaveToDesignBoardContext outside SaveToDesignBoardContext.Provider'
        );
    }
    return context;
};
