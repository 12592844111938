import type { ChooseDesignBoardModalProps } from '@archipro-design/aria';
import { DesignBoardPinTargetType, DesignBoardRole } from 'generated/graphql';
import type {
    BoardOwnerTypeFilter,
    PinTargetTitleFilter,
    SideNavItems,
} from '~/modules/design-board/type/design-board-types';

export const MAX_BOARDS_TO_RETRIEVE = 1000;
export const DESIGN_BOARD_REMIX_API = `/remix-api/design-board/design-board`;
export const GET_DESIGN_BOARD_TEXT_ONLY_LIST_API = `/remix-api/design-board/design-board-text-only-list`;

export const API_ACTIONS = {
    GET_DESIGN_BOARD_LIST: 'GET_DESIGN_BOARD_LIST',
    SAVE_ITEM_TO_DESIGN_BOARD: 'SAVE_ITEM_TO_DESIGN_BOARD',
    CREATE_DESIGN_BOARD_AND_SAVE_ITEM: 'CREATE_DESIGN_BOARD_AND_SAVE_ITEM',
    CREATE_DESIGN_BOARD_AND_SAVE_MULTIPLE_ITEMS:
        'CREATE_DESIGN_BOARD_AND_SAVE_MULTIPLE_ITEMS',
    SAVE_MULTIPLE_ITEMS: 'SAVE_MULTIPLE_ITEMS',
    DELETE_ITEM_FROM_DESIGN_BOARD: 'DELETE_ITEM_FROM_DESIGN_BOARD',
    UPDATE_DESIGN_BOARD: 'UPDATE_DESIGN_BOARD',
    DELETE_DESIGN_BOARD: 'DELETE_DESIGN_BOARD',
    MOVE_DESIGN_BOARD_PINS: 'MOVE_DESIGN_BOARD_PINS',
    COPY_DESIGN_BOARD_PINS: 'COPY_DESIGN_BOARD_PINS',
    DELETE_DESIGN_BOARD_PINS: 'DELETE_DESIGN_BOARD_PINS',
    CREATE_DESIGN_BOARD_AND_MOVE_ITEM: 'CREATE_DESIGN_BOARD_AND_MOVE_ITEM',
    CREATE_DESIGN_BOARD_AND_COPY_ITEM: 'CREATE_DESIGN_BOARD_AND_COPY_ITEM',
    UPLOAD_DESIGN_BOARD_PIN: 'UPLOAD_DESIGN_BOARD_PIN',
};

export const USER_FAVOURITED_ITEM_STORAGE_KEY = 'userFavouritedItems';
export const USER_BOARD_LIST_STORAGE_KEY = 'userBoardList';
export const USER_ID_STORAGE_KEY = 'userId';

export const DEFAULT_BOARD_DATA: ChooseDesignBoardModalProps['designBoards'] = [
    {
        title: 'Default board',
        id: 0,
        isPrivate: false,
        isSaved: false,
    },
];

export const DEFAULT_BOARD_TITLE = 'My first design board';

export const EMPTY_SIDE_NAV_ITEM: SideNavItems = {
    title: '',
    link: '',
};

export const DESIGN_BOARD_HOME_LINK = '/member/design-boards';
export const DESIGN_BOARD_ALL_PINS_LINK = `${DESIGN_BOARD_HOME_LINK}/pins`;

export const DEFAULT_SIDE_NAV_ITEM: SideNavItems = {
    title: 'Design Boards',
    link: DESIGN_BOARD_HOME_LINK,
};

export const PROJECT_INDEX_PAGE_SIDE_ITEMS: SideNavItems[] = [
    DEFAULT_SIDE_NAV_ITEM,
    {
        title: 'Schedules',
        preview: true,
        link: '/member/schedules',
    },
    {
        title: 'Plans',
        preview: true,
        link: '/member/plans',
    },
];

export const PIN_TILE_LIMIT_PER_PAGE = 50;
export const BOARD_TILE_LIMIT_PER_PAGE = 40;
export const PIN_TYPE_PARAM_NAME = 'targetItemType';
export const ROLE_TYPE_PARAM_NAME = 'boardRoleType';
export const BOARD_TILE_OVERLAY_CLASS = 'board-tile-overlay';

export const PIN_TITLE_AND_TYPE: PinTargetTitleFilter[] = [
    {
        title: 'Products',
        type: DesignBoardPinTargetType.Product,
    },
    {
        title: 'Projects',
        type: DesignBoardPinTargetType.Project,
    },
    {
        title: 'Professionals',
        type: DesignBoardPinTargetType.Professional,
    },
    {
        title: 'Articles',
        type: DesignBoardPinTargetType.Article,
    },
    {
        title: 'Uploads',
        type: DesignBoardPinTargetType.MemberUpload,
    },
    {
        title: 'Photos',
        type: DesignBoardPinTargetType.Photo,
    },
];

export const ALL_SAVED_PIN_NAV_ITEM: SideNavItems = {
    title: 'All Saves',
    link: '.',
    type: null,
};

export const BOARD_LIST_OWNER_FILTER: BoardOwnerTypeFilter[] = [
    {
        title: 'All boards',
        type: null,
    },
    {
        title: 'Created by me',
        type: DesignBoardRole.Owner,
    },
    {
        title: 'Collaborating',
        type: DesignBoardRole.Collaborator,
    },
    {
        title: 'Following',
        type: DesignBoardRole.Follower,
    },
];

export const LOGO_PLACEHOLDER_BG_COLOR = 'white';
export const LOGO_TRANSPARENT_BG_COLOR = '#F2F1F0';
export const UPLOAD_PLACEHOLDER_BG_IMG =
    '/assets/website/design-boards/images/custom_upload_thumbnail.png';

export type SocialMediaShareType =
    | 'email'
    | 'copyLink'
    | 'facebook'
    | 'instagram'
    | 'linkedin'
    | 'pinterest'
    | 'whatsapp';

export const SHOW_COMMENT = false;
export const SHOW_ORGANIZE = false;
export const SHOW_SHARE_PINS = false;
export const SHOW_ADD_BUTTON_AFTER_AVATAR = false;
