

import {
    Button,
    useStyles,
    Flex,
    Caption,
    useTheme
} from '@archipro-design/aria';
import * as S from './RightSideNewBoarderDrawer.style';
import { CloseLine } from '@archipro-design/icons';

interface DesignBoardDrawerHeaderProps {
    onClose: () => void;
    headerTitle?: string;
}

export const DesignBoardDrawerHeader = ({
    onClose,
    headerTitle = 'Create a board',
}: DesignBoardDrawerHeaderProps) => {
    const { css } = useStyles();
    const theme = useTheme();

    const onClickCloseButton = (e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();

    }

    return (
        <div>
            <Button
                className={css(S.CloseButton)}
                disabled={false}
                onClick={onClickCloseButton}
                size={30}
                color="grey"
                variables={{
                    buttonPadding: 0,
                    iconPadding: 0,
                    greyButtonBackgroundColorHover:
                        theme.siteVariables.colors.transparency.black['6%'],
                    greyButtonBackgroundColorNormal: 'transparent',
                }}
                icon={<CloseLine />}
            />

            <Flex>
                <Caption
                    className={css(S.Header)}
                    variant="01"
                    weight={'medium'}
                >
                    {headerTitle}
                </Caption>
            </Flex>
        </div>
    );
};
