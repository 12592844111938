import { z } from 'zod';
import {
    TITLE_MAX_LENGTH,
    TITLE_MIN_LENGTH,
} from '~/modules/design-board/config/right-side-design-board-drawer';

const allowedActions = z.enum([
    'MOVE_DESIGN_BOARD_PINS',
    'COPY_DESIGN_BOARD_PINS',
    'UPDATE_DESIGN_BOARD',
]);

export const NewBoardValidator = z.object({
    title: z
        .string({
            required_error: 'Title is required',
        })
        .min(1, {
            message: `Title is required`,
        })
        .min(TITLE_MIN_LENGTH, {
            message: `Title must have at least ${TITLE_MIN_LENGTH} characters`,
        })
        .max(TITLE_MAX_LENGTH, {
            message: `Maximum limit is ${TITLE_MAX_LENGTH} characters`,
        }),
    description: z.string().optional(),
    isPrivate: z.boolean().optional(),
});

export type NewBoardFormShape = z.infer<typeof NewBoardValidator>;

export const copyPinsSchema = z.object({
    _action: z.literal(allowedActions.enum.COPY_DESIGN_BOARD_PINS),
    boardId: z.coerce.number(),
    destinationBoardId: z.coerce.number(),
    pinIds: z.coerce.number().or(z.coerce.string()),
});

export const movePinsSchema = z.object({
    _action: z.literal(allowedActions.enum.MOVE_DESIGN_BOARD_PINS),
    boardId: z.coerce.number(),
    destinationBoardId: z.coerce.number(),
    pinIds: z.coerce.number().or(z.coerce.string()),
});
