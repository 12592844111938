import {
    Button,
    pxToRem,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import type { BoardListItem } from '~/modules/design-board/type';
import * as S from './DesignBoardDrawerSelectPins.style';

interface DesignBoardDrawerSelectPinsListItemProps extends BoardListItem {
    onSave: (id: number, boardTitle: string, urlSegment: string) => void;
    clickedId: number | null;
    label: string;
    isFavourited?: boolean;
    urlSegment: string;
}

/**
 * NoLoad = there is no selected board id
 * SelectedId = the selected ID is the same as this item
 * NotSelectedId = an ID has been selected but it is not for this item
 */
export type LoadingStatus = 'NoLoad' | 'SelectedId' | 'NotSelectedId';

export const DesignBoardDrawerSelectPinsListItem = ({
    boardName,
    id,
    onSave,
    label,
    clickedId,
    isFavourited = false,
    urlSegment,
}: DesignBoardDrawerSelectPinsListItemProps) => {
    const loadingStatus: LoadingStatus =
        clickedId === null
            ? 'NoLoad'
            : clickedId === id
            ? 'SelectedId'
            : 'NotSelectedId';

    const isDisabled = isFavourited || loadingStatus === 'SelectedId';
    const { css } = useStyles({ loadingStatus, isFavourited, isDisabled });
    const { mobile: isMobile } = useAppDisplayModeContext();

    return (
        <div
            className={css(S.BoardListItem)}
            onClick={() => !isDisabled && onSave(id, boardName, urlSegment)}
        >
            <label>{boardName}</label>
            <Button
                loading={loadingStatus === 'SelectedId'}
                disabled={isDisabled}
                size={14}
                variables={{
                    buttonPadding: pxToRem(isMobile ? 8.5 : 10),
                }}
            >
                {label}
            </Button>
        </div>
    );
};
