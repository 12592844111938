import {
    Button,
    pxArrayToRem,
    pxToRem,
    useAppDisplayModeContext,
    useStyles,
    useTheme,
} from '@archipro-design/aria';
import * as S from './RightSideNewBoarderDrawer.style';

export interface ButtonSecondaryProps {
    onClick: () => void;
    label: string;
}

interface DesignBoardDrawerFooterProps {
    submitButtonLabel: string;
    buttonSecondary?: ButtonSecondaryProps;
    loading?: boolean;
    buttonPrimaryAction?: () => void;
}

export const DesignBoardDrawerFooter = ({
    submitButtonLabel,
    buttonSecondary,
    buttonPrimaryAction,
    loading = false,
}: DesignBoardDrawerFooterProps) => {
    const { css } = useStyles({ buttonSecondary });
    const theme = useTheme();
    const { mobile: isMobile } = useAppDisplayModeContext();

    const buttonPadding = isMobile
        ? pxArrayToRem([20, 14, 18, 14])
        : pxToRem(buttonSecondary ? 14 : 16);
    const size = isMobile ? 14 : buttonSecondary ? 16 : 20;
    const darkOutlinedButtonBorderNormal = `1px solid ${theme.siteVariables.colors.charcoal[250]}`;

    return (
        <div className={css(S.BottomButtons)}>
            {buttonSecondary && (
                <Button
                    size={size}
                    fluid
                    color="dark"
                    variant="outlined"
                    variables={{
                        buttonPadding,
                        darkOutlinedButtonBorderNormal,
                        ...(isMobile && {
                            buttonPadding: pxArrayToRem([19, 13, 17, 13]),
                            buttonSize14TextFontSize: pxToRem(12),
                            buttonSize14LineHeight: pxToRem(12),
                            letterSpacing: pxToRem(0.9),
                        }),
                    }}
                    onClick={() => buttonSecondary.onClick()}
                    key={`secondaryButton ${buttonSecondary.label}`}
                    type="button"
                    className={css(S.SecondaryButton)}
                    disabled={loading}
                >
                    <div>{buttonSecondary.label}</div>
                </Button>
            )}

            <Button
                size={size}
                fluid
                variables={{
                    buttonPadding,
                    ...(isMobile && {
                        buttonSize14TextFontSize: pxToRem(12),
                        buttonSize14LineHeight: pxToRem(12),
                        letterSpacing: pxToRem(0.9),
                    }),
                }}
                loading={loading}
                disabled={loading}
                type={'submit'}
                key={`primaryButton ${submitButtonLabel}`}
                onClick={buttonPrimaryAction}
                className={css(S.SaveButton)}
            >
                <div>{submitButtonLabel}</div>
            </Button>
        </div>
    );
};
